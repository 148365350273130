import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {BehaviorSubject} from 'rxjs';
import {AppSettings} from '../../app.settings';
import ReconnectingWebSocket from 'reconnecting-websocket';
import {UsersService} from '../users/users.service';


export interface CarrierStatus {
    totalCount: number;
    errors: Array<string>;
    newUserCount: number;
    updatedCount: number;
    carriers: Array<{name: string, count?: number}>;
}

@Injectable({
    providedIn: 'root'
})
export class UsersInsuranceService {

    public insuranceStateChangedObservable = new BehaviorSubject(null);
    public insuranceStateCountObservable = new BehaviorSubject({census: 0, eligible: 0, enrolled: 0});

    constructor(
        private restangular: Restangular,
        private usersService: UsersService
    ) { }

    // PRIMARY

    getInsurancePrimary(userId: string): Promise<any> {
        return this.restangular.one('users/insurance/primary', userId).get().toPromise();
    }

    createInsurancePrimary(userId: string, gender: string, metadata: any, tobaccoUseDate: string): Promise<any> {
        return this.restangular.one('users/insurance/primary', userId).customPOST({
            gender,
            metadata,
            tobaccoUseDate,
        }).toPromise();
    }

    modifyInsurancePrimary(userId: string, fields: any): Promise<any> {
        return this.restangular.one('users/insurance/primary', userId).customPUT({
            ...fields
        }).toPromise();
    }

    deleteInsurancePrimary(userId: string): Promise<any> {
        return this.restangular.one('users/insurance/primary', userId).remove().toPromise();
    }

    // DEPENDENTS

    getInsuranceDependents(userId: string): Promise<any> {
        return this.restangular.one('users/insurance/dependent', userId).getList().toPromise();
    }

    createInsuranceDependent(userId: string, gender: string, metadata: any, tobaccoUseDate: string, firstName: string, lastName: string, dob: string, relationship: string): Promise<any> {
        return this.restangular.one('users/insurance/dependent', userId).customPOST({
            gender,
            metadata,
            tobaccoUseDate,
            firstName,
            lastName,
            dob,
            relationship,
        }).toPromise();
    }

    modifyInsuranceDependent(userId: string, dependentId: string, fields: any): Promise<any> {
        return this.restangular.one('users/insurance/dependent', userId).one(dependentId).customPUT({
            ...fields
        }).toPromise();
    }

    deleteInsuranceDependent(userId: string, dependentId: string): Promise<any> {
        return this.restangular.one('users/insurance/dependent', userId).one(dependentId).remove().toPromise();
    }

    // BENEFICIARIES

    getInsuranceBeneficiaries(userId: string): Promise<any> {
        return this.restangular.one('users/insurance/beneficiary', userId).getList().toPromise();
    }

    createInsuranceBeneficiary(userId: string, beneficiaryType: string, firstName: string, lastName: string, ssn: string, relationship: string): Promise<any> {
        return this.restangular.one('users/insurance/beneficiary', userId).customPOST({
            beneficiaryType,
            firstName,
            lastName,
            ssn,
            relationship,
        }).toPromise();
    }

    modifyInsuranceBeneficiary(userId: string, beneficiaryId: string, fields: any): Promise<any> {
        return this.restangular.one('users/insurance/beneficiary', userId).one(beneficiaryId).customPUT({
            ...fields
        }).toPromise();
    }

    deleteInsuranceBeneficiary(userId: string, beneficiaryId: string): Promise<any> {
        return this.restangular.one('users/insurance/beneficiary', userId).one(beneficiaryId).remove().toPromise();
    }

    // CENSUS-ELIGIBILITY-ENROLLMENT STATE

    getCounts(companyId: string): Promise<any> {
        return this.restangular.one('users/insurance/all').one(companyId, 'counts').get().toPromise().then((result: any) => {
            this.insuranceStateCountObservable.next(result);
            return result;
        });
    }

    getInsuranceState(companyId: string, state: string, offset: number, limit: number, filter: any, sort: string): Promise<any> {
        return this.restangular.one('users/insurance', state).one(`${companyId}?offset=${offset}&limit=${limit}&filter=${JSON.stringify(filter)}&sort=${sort}`).getList().toPromise();
    }

    createInsuranceState(companyId: string, userId: string, email: string, metadata: any, state: string): Promise<any> {
        metadata = {upload: metadata};
        return this.restangular.one('users/insurance', state).one(companyId).customPOST({
            companyId,
            userId,
            email,
            metadata,
            state,
        }).toPromise().then((result: any) => {
            this.insuranceStateChangedObservable.next(null);
            return result;
        });
    }

    modifyInsuranceState(stateId: string, fields: any): Promise<any> {
        return this.restangular.one('users/insurance/state', stateId).customPUT({
            ...fields
        }).toPromise().then((result: any) => {
            this.insuranceStateChangedObservable.next(null);
            return result;
        });
    }

    deleteInsuranceState(stateId: string): Promise<any> {
        return this.restangular.one('users/insurance/state', stateId).remove().toPromise().then((result: any) => {
            this.insuranceStateChangedObservable.next(null);
            return result;
        });
    }

    deleteAllInsuranceStates(companyId: string): Promise<any> {
        return this.restangular.one('users/insurance/company', companyId).one('all').remove().toPromise().then((result: any) => {
            this.insuranceStateChangedObservable.next(null);
            return result;
        });
    }

    startWebsocket(restangularCall: any, callback: (isData: boolean, payload: any, rws?: any) => void): any {
        const urlParts = restangularCall.getRestangularUrl().split('//');
        const rws = new ReconnectingWebSocket(`ws${urlParts[0] === 'https:' ? 's' : ''}://${urlParts[1]}?token=${this.usersService.getCurrentUserId()}`, [], {
            maxReconnectionDelay: 100,
            minReconnectionDelay: 10,
            reconnectionDelayGrowFactor: 1.3,
            minUptime: 5000,
            connectionTimeout: 1000,
            maxRetries: Infinity,
            maxEnqueuedMessages: Infinity,
            startClosed: false,
            debug: false
        });
        rws.onmessage = (wsEvent) => {
            callback(true, wsEvent.data, rws);
        };
        rws.onerror = (error) => {
            console.log('WEBSOCKET ERROR', error)
        };
        return rws;
    }

    uploadInsuranceState(companyId: string, state: string, data: any, callback: (isData: boolean, payload: any) => void): Promise<any> {
        const restangularCall = this.restangular.one('users/insurance', state).one('upload', companyId);
        const rws = this.startWebsocket(restangularCall, callback);
        callback(false, rws);
        return restangularCall.customPUT(data, undefined, undefined, { 'content-type': 'text/html' }).toPromise().then((result: any) => {
            this.insuranceStateChangedObservable.next(null);
            rws.close();
            return result;
        }).catch((error: any) => {
            this.insuranceStateChangedObservable.next(null);
            rws.close();
            throw error;
        });
    }

    invite(companyId: string, state: string, ids: Array<string>): Promise<any> {
        return this.restangular.one('users/insurance', state).one('invite', companyId).customPUT({
            ids
        }).toPromise().then((result: any) => {
            this.insuranceStateChangedObservable.next(null);
            return result;
        });
    }

    getCarrierSyncStatus(companyId: string): Promise<CarrierStatus> {
        return this.restangular.one('users/insurance/company', companyId).one('carriersync', 'status').get().toPromise();
    }

    syncWithCarriers(companyId: string): Promise<CarrierStatus> {
        return this.restangular.one('users/insurance/company', companyId).one('carriersync').put().toPromise();
    }

}
