import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Table} from '../member-manager/member-manager.component';
import {FieldType, MemberAddeditComponent, MemberAddeditDialogModel} from '../member-addedit/member-addedit.component';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {UsersInsuranceService} from '../../../services/usersinsurance/users.insurance.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSelectChange} from '@angular/material/select';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, tap} from 'rxjs/operators';
import {enrollAddedFields} from '../client-enrollment/client-enrollment.component';
import {MatDialog} from '@angular/material/dialog';
import * as _ from 'lodash';
import {TeamCompaniesService} from '../../../services/teamcompanies/teamcompanies.service';
import {VendorProductsService} from '../../../services/vendorproducts/vendorproducts.service';

@Component({
  selector: 'app-client-eligibility',
  templateUrl: './client-eligibility.component.html',
  styleUrls: ['./client-eligibility.component.scss']
})
export class ClientEligibilityComponent implements AfterViewInit{

  @Input() clientId = '';
  @ViewChild('search') search: ElementRef | undefined;

  public reload = false;
  public itemCount = 0;

  public table: Table = {
    title: 'Eligible Member',
    addEditFields: [
      {name: 'Given Name', label: 'First Name', fieldType: FieldType.F_STRING, required: true},
      {name: 'Family Name', label: 'Last Name', fieldType: FieldType.F_STRING, required: true},
      {name: 'SSN', label: 'Social Security Number', fieldType: FieldType.F_SSN, required: true},
    ],
    columnsToDisplay: [
      {
        data: (row: { [x: string]: any; }) => row['Given Name'],
        label: 'First Name',
      },
      {
        data: (row: { [x: string]: any; }) => row['Family Name'],
        label: 'Last Name'
      },
      {
        data: (row: { [x: string]: any; }) => row.SSN,
        label: 'SSN'
      },
      {
        data: (row: { [x: string]: any; }) => row.state + (row.metadata.deletepending ? ', pending delete' : ''),
        label: 'State'
      }
    ],
    menuItems: [
      {name: 'Make Ineligible', disabled: this.ineligibleDisabled.bind(this), click: this.makeIneligible.bind(this)},
      {name: 'Enroll', disabled: this.enrollDisabled.bind(this), click: this.enroll.bind(this)}
    ],
    templateUrl: '/assets/files/Wallit-eligibility.xlsx',
    where: {
      limit: 50,
      offset: 0,
      sort: '',
      filter: {}
    }
  };

  constructor(
      private usersInsuranceService: UsersInsuranceService,
      private snackBar: MatSnackBar,
      private dialog: MatDialog,
      private teamCompaniesService: TeamCompaniesService,
      private vendorProductsService: VendorProductsService
  ) {
    this.usersInsuranceService.insuranceStateCountObservable.subscribe(result => {
      this.itemCount = result.eligible;
    });
  }

  ngAfterViewInit(): void {
    fromEvent(this.search?.nativeElement, 'keyup').pipe(
        filter(Boolean),
        debounceTime(300),
        distinctUntilChanged(),
        tap((): void => {
          Object.assign(this.table.where.filter, {search: this.search?.nativeElement.value.trim().toLowerCase()});
          this.reload = !this.reload;
    })).subscribe();
  }

  enrollDisabled(item: any): boolean {
    return item.state !== 'eligible';
  }

  ineligibleDisabled(item: any): boolean {
    return item.state !== 'eligible';
  }

  makeIneligible(item: any): Promise<boolean> {
    return this.usersInsuranceService.modifyInsuranceState(item.id, {state: 'census'}).then(() => {
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'Member set to ineligible' });
      return true;
    });
  }

  enroll(item: any): Promise<boolean> {
    return this.teamCompaniesService.getCompany(this.clientId).then(company => {
      return this.vendorProductsService.getAllVendorProducts().then(products => {
        products = products.filter((product: { id: string; }) => company.payrollConfig.products?.find((productId: string) => productId === product.id));
        const productTableField = _.find(enrollAddedFields, field => field.name === 'Product');
        // @ts-ignore
        productTableField.values = products.map((product: any) => `${product.vendorName} ${product.productName}:${product.vendorSlug}-${product.productSlug}`);
        const dialogRef = this.dialog.open(MemberAddeditComponent, {
          data: new MemberAddeditDialogModel(item, enrollAddedFields, 'Required Enrollment Fields')
        });
        return new Promise((resolve, reject) => {
          dialogRef.afterClosed().subscribe((dialogResult: any) => {
            if (dialogResult) {
              item.metadata.upload = _.merge({}, item.metadata.upload, dialogResult);
              this.usersInsuranceService.modifyInsuranceState(item.id, {state: 'enrolled', metadata: item.metadata}).then(() => {
                this.snackBar.openFromComponent(SnackBarComponent, {data: 'Member set to enrolled'});
                resolve(true);
              });
            } else {
              resolve(false);
            }
          });
        });
      });
    });
  }

  setStateFilter(event: MatSelectChange): void {
    Object.assign(this.table.where.filter, {state: event.value});
    this.reload = !this.reload;
  }

  tableUpdated(count: number): void {
    this.itemCount = count;
  }

  pageEvent(event: any): void {
    this.table.where.limit = event.pageSize;
    this.table.where.offset = event.pageIndex * event.pageSize;
    this.reload = !this.reload;
  }

}
